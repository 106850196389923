@import '../abstracts/colors';
@import '../abstracts/flex';

.navbar {
  $height: 56px;
  @extend .flex-row;
  width: 100vw;
  justify-content: space-between;
  align-items: center;
  background-color: $primary;
  position: fixed;
  top: 0;
  height: $height;
  z-index: 70;
  
  img {
    height: $height;
    padding: 1rem;
  }

  button {
    margin-right: 1rem;
    background: $primary;
    color: white;
    outline: none;
    border: none;
    :focus {
      outline: none;
    }
  }
}

.hamburger-menu {
  z-index: 10;
  width: 100vw;
  height: 100vh;
  background-color: #C0C0C088;
  position: fixed;
  top: 56px;

  &__items {
    position: fixed;
    top: 56px;
    left: 0;
    width: 75%;
    z-index: 50;
    background-color: $primary;
    height: 100vh;
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center; 
    padding: 16px 12px;
    color: white;
    font-size: 20px;

    div {
      padding-left: 12px;
    }
  }
}
