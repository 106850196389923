// @use '../abstracts' as *;
@import '../abstracts/colors';

.bg--default {
  background-color: purple;
  border: 1.5px solid purple;
}
.bg--house_construction {
  background-color: #FF4D4D;
  border: 1.5px solid #FF4D4D;
}

.bg--house_destruction {
  background-color: #F79510;
  border: 1.5px solid darkorange;
}

.bg--large_property_destruction {
  background-color: #2374F7;
  border: 1.5px solid #2374F7;
}

.bg--large_property_construction {
  background-color: green;
  border: 1.5px solid darkgreen;
}

.overflow-hidden {
  overflow: hidden;
}

.circle {
  @include circle(20px);
}

.nametag {
  min-width: 100px;
  max-width: 125px;
  font-size: 18px;
  text-align: center;
  border-radius: 20px;
  margin:  9px;
  padding: 4px;
  &--small{
    font-size: 14px;
    padding: 3px;
    min-width: 70px;
    border-radius: 20px;
    text-align: center;
  }
}

.carousel {
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.task--detail {
  background-color: $primary;
  min-height: 100vh;
  height: 100%;
  color: white;
  padding: 34px 11px;
  h2 {
    font-size: 20px;
  }
  &__title {
    h1 {
      font-size: 32px;
      font-weight: 700;
    }
    h3 {
      font-size: 17px;
    }

  }
  &__location{
    align-items: center;

  }
  &__location {
  }
  &__date {
    margin-bottom: 10px;
    font-size: 16px;
    div div {
      height: 24px;
    }
  }
  &__time {
    margin-bottom: 10px;
    font-size: 16px;
  }
  &__leader{
    ul {
      justify-content: center;
    }
  }
  &__member{
    ul{
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  &__description{
    width: 100%;
    textarea {
      margin: 27px 0px 35px 35px;
      width: calc(100% - 70px);
      min-height: 180px;
      border-radius: 12px;
      padding: 4px;
      color: black;
    }
  }
  &__images{
    button {
      @include circle(35px);
      background-color: #707070;
    }
    button:disabled {
      background-color: #CCCCCC,
    }
    img {
      width: 80px;
      height: 106px;
    }
  }
  &__button{
    padding-top: 71px;
    button {
      width: 100%;
    }
  }
}

.task-list {
  margin-top: 20px;
  margin-bottom: 20px;

  &-pagination {
    margin: 4px;
    width: 40px;
    height: 40px;
    &--active {
      background-color: $primary;
      border-radius: 8px;
      color: white;
    }
    &--inactive {
      background-color: white;
      border: 1px solid $primary;
      border-radius: 8px;
    }
  }


  &-item {
    border-radius: 15px;
    border: 1px solid $primary;
    padding: 4px;
    padding-top: 22px;
    padding-bottom: 20px;
    margin: 16px;
    box-shadow: 5px 5px 5px 0px #AAAAAA;
    background-color: $primary;
    color: white;
    &__title {
      margin-top: 2px;
      width: 80px;
    }
    &__value {
      margin-top: 2px;
      overflow: hidden;
      min-width: 80px;
    }
  }
}

@mixin new-task-container {
  margin-top: 12px;
  width: 300px;
}

.new-task {
  $default-width: 325px;
  padding: 10px;
  input {
    padding: 8px;
    margin-top: 12px;
    border-radius: 6px;
    border: 1px solid #CCCCCC;
    box-shadow: 5px 5px 5px 0px #CCCCCC;
  }
  textarea {
    padding: 8px;
    border-radius: 6px;
    border: 1px solid #CCCCCC;
  }

  p {
    font-size: 20px;
    padding-left: 4px;
  }

  select{
    width: 100%;
  }

  option{
    width: 100%;
    height: 30px;
    padding: 4px;
    :checked {
      background-color: lightblue;
    }
    :focus {
    }
  }

  &__location {
    @include new-task-container
    width: $default-width;
    input {
      width: 310px;
    }
  }
  &__type {
    @include new-task-container
    width: $default-width;
  }
  &__date {
    @include new-task-container
    width: $default-width;
  }
  &__time {
    @include new-task-container
    width: $default-width;
  }
  &__address {
    @include new-task-container
    width: $default-width;
    input {
      width: 310px;
    }
  }
  &__images {
    input {
      width: $default-width;
    }
  }
  &__leader {
    @include new-task-container
    width: $default-width;
  }
  &__members {
    @include new-task-container
    width: $default-width;
  }
  &__memo {
    @include new-task-container
    width: $default-width;
    textarea {
      width: 315px;
      min-height: 203px;
    }
  }
}
