// @use '../abstracts' as *;
@import '../abstracts/colors';

.sns-post {
  &--preview {
    width: 100%;
    border-radius: 15px;
    border: 1px solid $primary;
    box-shadow: 10px 10px 6px #CCCCCC;
  }


  &--detail {
    width: 100%;
    border-radius: 15px;
    border: 1px solid $primary;
    box-shadow: 10px 10px 6px #CCCCCC;
  }

  &--wrapper {
    @extend .flex-row;
    padding-left: 21px;
    padding-right: 18px;
    padding-top: 10px;
  }

  &__profile{
    @include circle(35px);
    border: 1px solid #707070;
  }

  &--new {
    height: 25vh;
    width: 100%;
    border: 1px solid $primary;
    border-radius: 15px;
    box-shadow: 5px 5px 5px 0 #CCCCCC;
    padding: 8px;
  }
  &__username {
    font-size: 18px;
    font-weight: 700;
  }

  &__content {
    font-size: 16px;
  }

  &__timestamp {
    font-size: 12px;
    color: #999999;
  }

  &__comment-icon {
    color: $primary;
    float: left;

    p {
      padding: 0 1em;
    }
  }
}


.sns-comment {
  &-footer {
    width: 100vw;
    position: fixed;
    bottom: 0;
    height: 32px;
    padding: 8px 0px 8px 8px;
    background-color: white;
    border-top: 1px solid #CCCCCC;
    &__form {
      flex-direction: row;
      justify-content: space-around;
      display: flex;
    }

    &__input {
      width: 70%;
      border-radius: 15px;
      border: none;
      padding: 8px;
      background-color: #EEEEEE;
      flex-wrap: wrap;
    }

    &_button {
      padding: 8px;
      color: $primary;
    }
  }
}

