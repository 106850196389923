// @use '../abstracts' as *;
@import '../abstracts/colors';

.calendar {
  width: 100vw;
  position: fixed;
  top: 56px;
  left: 0px;
  right: 0px;
  &__header {
    padding-top: 16px;
    &--date {
      font-size: 25px;
    }
    &--icon {
      font-size: 30px;
    }
  }

  &__body {
  }

  &__date {
    @include circle(33px);
    text-align: center;
    background-color: white

    &--same-month {
      color: black;
    }

    &--different-month {
      color: #CCCCCC;
    }
    &--selected {
      color: white;
      background-color: $primary;
    }
  }
  &__project-item {
    @include circle(7px);
  }
}



.scroll-calendar {
  position: absolute;
  border-radius: 50px 50px 0px 0px;
  height: auto;
  top: 550px;
  align-items: center;
  background-color: $primary;
  padding-bottom: 40px;
  &__wrapper {
  }
  &__time{
    height: 30px;
    width: 75px;
  }

  &__group{
    @include circle(14px);
  }

  &__task{
    width: 200px;
    position: relative;
  }

  &__title{
    overflow-x: hidden;
    overflow-y: hidden;
    height: 24px;
    width: 200px;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100vw;
    padding: 0px;
  }

  &__button {
    color: white;
    margin: 0 20px;
  }

  &__names {
    display: flex;
    flex-direction: row;
  }
}



