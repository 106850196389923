.login-screen {
  width: 100vw;
  display: flex;
  flex-direction: column;
  margin-top: 60px;

  &__field {
    display: flex;
    flex-direction: column;
    padding-top: 58px;

    label {
      font-size: 12px;
    }

    input {
      padding-top: 18px;
      border: none;
      border-bottom: 2px solid $primary;
    }
  }

  button {
    margin: 8px;
    margin-top: 100px;
    border-radius: 16px;
  }
}
