.dashboard {
  &-item {
    $size: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: $size;
    height: $size;
    color: white;
    border-radius: 16px;
    background-color: $primary;
    box-shadow: 5px 5px 6px 3px #CCCCCC;

    margin-top: 34.5px;
    margin-bottom: 34.5px;

    &__icon {
      margin-top: 12px;
    }
    p {
      font-size: 17px;
      margin-top: 12px;
    }
  }
}
