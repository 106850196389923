
.select {
  height: auto;

  &__input {
    display: flex;
    flex-direction: row;
    border: 1px solid #CCCCCC;
    border-radius: 8px;
    height: 37px;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    flex-wrap: wrap;
  }
  &__icon {
    padding-right: 3px;
  }
  &__multi-selected {
    background-color: salmon;
    margin: 2px;
    border-radius: 6px;
    padding: 4px;
    overflow: hidden;
    min-width: 30px;
    text-align: center;
    color: white;
    height: 24px;
    overflow: hidden;
  }
  &__options {
    border: 1px solid #CCCCCC;
    background-color: white;
    border-radius: 8px;
    min-height: 39px;
    position: absolute;
    &-single {
      border: 1px solid #CCCCCC;
      background-color: white;
      border-radius: 8px;
      min-height: 39px;
    }
  }
  &__option {
    height: 20px;
    padding: 8px;
    width: 100%;

    option {
      :checked {
        background-color: yellowgreen;
      }
    }

  }
}
