// @use '../abstracts' as *;
@import '../abstracts/colors';

.flex-center {
  align-items: center;
  justify-content: center;
}

.user-list {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  &__buttons {
    position: fixed;
    bottom: 135px;
    right: 15px;
    button {
    }
  }

  &__container {
    margin-bottom: 60px;
  }

  &__search {
    input {
      border-radius: 29px;
      border: 1px solid black;
      padding: 12px;
      width: 285px;
    }
    width: 100%;
    margin-top: 31px;
    margin-bottom: 20px;
  }


  &-item {
    width: 268px;
    height: 66px;
    color: white;
    background-color: $primary;
    margin: 15px;
    border-radius: 15px;
    padding: 8px;
    box-shadow: 6px 6px 5px 1px #CCCCCC;
    &__image {
      img {
        @include circle(50px);
        background-color: white;
        margin-right: 6px;
        object-fit: cover;
      }
    }
    &__label {
      width: 90px;
    }
  }
  &__back-button {
    position: fixed;
    height: 60px;
    width: 100%;
    background-color: #FFFFFF88;
    bottom: 0px;
    box-shadow: 0px 5px 5px 7px #FFFFFF88;
  }

}

.user-detail {
  margin-top: 30px;
  &__image {
    position: relative;
    input {
      display: none;
    }
    img {
      @include circle(100px);
      object-fit: cover;
    }
    span {
      position: absolute;
      top: 80px;
      right: -10px;
    }
  }
  &__fields {
    height: 500px;
    margin-top: 25px;

  }
  &__field {
    $height: 72px;
    height: $height;
    min-width: 300px;
    position: relative;
    label {
      position: absolute;
      font-size: 12px;
      z-index: 20;
      left: 24px;
      background-color: white;
      padding: 4px;
      color: $primary;
    }
    p {
      border: 1px solid $primary;
      border-radius: 23px;
      width: 268px;
      position: absolute;
      height: 15px;
      top: 12px;
      padding: 15px;
    }
    input {
      border: 1px solid $primary;
      border-radius: 23px;
      width: 268px;
      position: absolute;
      height: 15px;
      top: 12px;
      padding: 15px;
    }
    span {
      position: absolute;
      right: 10px;
      top: 24px;
    }
  }
  &__buttons {
    position: fixed;
    bottom: 30px;
  }
}
