.flex {
  display: flex;

  &-row {
    @extend .flex;
    flex-direction: row;
  }

  &-col &-column {
    @extend .flex;
    flex-direction: column;
  }

  &-center {
    justify-content: center;
    align-items: center;
  }

  &-wrap {
    flex-wrap: wrap;
  }
}

.justify {
  &-around {
    justify-content: space-around;
  }
  &-between {
    justify-content: space-between;
  }
}

.align-items {
  &-center {
    align-items: center;
  }
}
