@import './abstracts/colors';
@import './abstracts/flex';
@import './abstracts/shapes';
@import './abstracts/text';

@import './components/sns';
@import './components/Task';
@import './components/Calendar';
@import './components/Users';
@import './components/navbar';
@import './components/dashboard';
@import './components/login';
@import './components/select';


body {
  margin: 0;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p.errorMessage {
  color: red
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  outline: none;
  border: none;
  background-color: rgba(255, 255, 255, 0);
}

.bg {
  &-orange {
    color: #FFFFFF;
    background-color: #F79510;
  }

  &-white {
    color: #000000;
    background-color: #FFFFFF;
  }
}

.display-none {
  display: 'none'
}

.modal {
  &--bg {
    position: fixed;
    top: 0;
    width: 100vw;
    min-height: 100vh;
    background-color: #00000066;
    z-index: 10;
  }

  &--fg {
    background-color: white;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 320px;
    height: 218px;
    border-radius: 23px;
  }

  &--button-container {
    position: absolute;
    right: 15px;
    bottom: 136px;
  }
}

.float-button {
  @include circle(50px);
  font-size: 12px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 20;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

:root {
}

:focus{
  outline: none;
}

button.big-button {
  border-radius: 16px;
  width: 242px;
  height: 47px;
  font-weight: 700;
}

button.primary{
  background-color: $primary;
  color: #FFFFFF;
}

button.secondary{
  color: $primary;
  background-color: #FFFFFF;
  border: 1px solid $primary;
}

.input-field {
  margin-top: 15px;
  height: 80px;

  label {
    font-size: 20px;
  }
  input {
    width: 325px;
    height: 42px;
    padding: 8px;
    border: 1px solid black;
    border-radius: 6px;
    box-shadow: 5px 5px 5px 0px #CCCCCC;
    margin-top: 10px;
  }
}


.checkbox-container {
  $size: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 28px;
  &__checkbox {
    box-shadow: 2px 2px 2px 0px #CCCCCC;
    margin-top: 8px;
    height: $size;
    width: $size;
  }
  &__label {
    font-size: 12px;
    color: #777777;
    height: $size;
  }
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  text-decoration: none;
  list-style: none;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
  color: black;
}


.ml{
  &-2{
    margin-left: 4px;
  }
  &-4{
    margin-left: 8px;
  }
}
